import { Box, Tooltip } from "@mui/material";
import { FC } from "react";
import Signal1 from "../../assets/signal_1.svg?react";
import Signal2 from "../../assets/signal_2.svg?react";
import Signal3 from "../../assets/signal_3.svg?react";
import Signal4 from "../../assets/signal_4.svg?react";
import Signal5 from "../../assets/signal_5.svg?react";

function calculateSignalStrength(dBm) {
  if (dBm <= -109) {
    return 1;
  } else if (dBm <= -103) {
    return 2;
  } else if (dBm <= -93) {
    return 3;
  } else if (dBm <= -70) {
    return 4;
  } else {
    return 5;
  }
}

const strengthIcons = {
  1: Signal1,
  2: Signal2,
  3: Signal3,
  4: Signal4,
  5: Signal5,
};

export type DeviceSignalIconProps = {
  signal?: number | null;
};

export const DeviceSignalIcon: FC<DeviceSignalIconProps> = ({ signal }) => {
  const bars = calculateSignalStrength(signal);
  const StrengthBarsIcon = strengthIcons[bars];

  return (
    signal && (
      <Tooltip title={`${signal} dBm`} placement="top" enterTouchDelay={0}>
        <Box display="flex" p={1} alignItems="center">
          <StrengthBarsIcon />
        </Box>
      </Tooltip>
    )
  );
};
