import { DialogActions, Button, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import WarningIcon from "@mui/icons-material/Warning";
import { ProjectQueryStatus } from "../hooks/useProjectQuery";

type Props = {
  status: ProjectQueryStatus;
};

export const ProjectErrorDialog = ({ status }: Props) => (
  <Dialog open={status === "error" || status === "not-found"}>
    <DialogContent>
      <DialogContentText component="div">
        <Box display="flex" flexDirection="column" textAlign="center">
          <Box fontSize="50px">
            <WarningIcon
              fontSize="inherit"
              color={status === "error" ? "error" : "warning"}
            />
          </Box>
          {status === "error" &&
            "Er ging iets fout tijdens het inladen van dit project."}
          {status === "not-found" &&
            "De pagina die u zoekt kon niet worden gevonden."}
        </Box>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {status === "error" && (
        <Button color="info" onClick={() => location.reload()}>
          Pagina herladen
        </Button>
      )}
      {status === "not-found" && (
        <Button component="a" color="info" href="https://google.com/">
          Naar de homepage
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
