import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

export const HomePage = () => {
  return (
    <Dialog open maxWidth="sm">
      <DialogTitle>Geluidsmonitor.nl</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Box>
            Geluidsmonitor.nl is een online hulpmiddel om realtime inzicht te
            krijgen in geluidsmetingen.
          </Box>
          <Table size="small" sx={{ my: 3 }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ minWidth: 175 }}>Versie</TableCell>
                <TableCell>{__APP_VERSION__}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ minWidth: 175 }}>Datum</TableCell>
                <TableCell>{__APP_TIMESTAMP__}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ minWidth: 175 }}>Build</TableCell>
                <TableCell>{__APP_GIT_BRANCH__}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ minWidth: 175 }}>Build hash</TableCell>
                <TableCell>{__APP_GIT_COMMIT_SHA__}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box>&copy; {__COPYRIGHT__} Geluidsmonitor.nl</Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
