import { ProjectDeviceValueUnitType } from "~/gql/graphql";

export const formatValueUnitTypeToString = (
  unit: ProjectDeviceValueUnitType
) => {
  switch (unit) {
    case "DB_A":
      return "dB(A)";
    case "DB_C":
      return "dB(C)";
    case "DB_Z":
      return "dB(Z)";
    default:
      return "";
  }
};

const numberFormat = new Intl.NumberFormat("nl-NL", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export const formatValueToString = (
  value: string | number | null | undefined
) => {
  if (value === null) return "---";
  if (typeof value === "number") {
    return numberFormat.format(value);
  }
  if (!value) return "?";

  return value;
};

const soundLimitNumberFormat = new Intl.NumberFormat("nl-NL", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

export const formatSoundLimitToString = (value: number | null | undefined) => {
  if (typeof value === "number") {
    return soundLimitNumberFormat.format(value);
  }

  return "-";
};

export const batteryVoltageFormat = new Intl.NumberFormat("nl-NL", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export const temperatureFormat = new Intl.NumberFormat("nl-NL", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});