import { Box, Container } from "@mui/material";

export const LayoutBase = ({ children }) => (
  <Box
    sx={{
      backgroundColor: (theme) => theme.palette.grey[900],
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    }}
  >
    <Container maxWidth="xl" disableGutters>
      {children}
    </Container>
  </Box>
);
