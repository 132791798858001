import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useMeasure, useUpdate } from "react-use";
import { formatSoundLimitToString, formatValueToString } from "~/formats";
import { ProjectDeviceValueUnitType } from "~/gql/graphql";

const backgroundColors = {
  0: "#008000",
  1: "#FB8C00",
  2: "#C62828",
};

export type PanelItemParameterProps = {
  title: string;
  value: number | null;
  soundLimit?: number | null;
  unit: ProjectDeviceValueUnitType;
};

const calculateSeverityLevel = (
  value: number | null,
  limit?: number | null
) => {
  if (!value || !limit) {
    return 0;
  }

  if (value >= limit) {
    return 2;
  }

  if (value >= limit - 3) {
    return 1;
  }

  return 0;
};

const severityLevelTimeouts = {
  0: 0,
  1: 4000,
  2: 4000,
};
const useBackgroundColor = (value: number | null, limit?: number | null) => {
  const update = useUpdate();
  const holdTimeout = useRef<NodeJS.Timeout | null>(null);
  const severityLevel = calculateSeverityLevel(value, limit);

  const severityLevelCooldownRef = useRef({
    0: 0,
    1: 0,
    2: 0,
  });

  const warningActive = Date.now() < severityLevelCooldownRef.current[1];
  const errorActive = Date.now() < severityLevelCooldownRef.current[2];

  const holdSeverityLevel = errorActive ? 2 : warningActive ? 1 : 0;
  const displayedSeverityLevel =
    holdSeverityLevel > severityLevel ? holdSeverityLevel : severityLevel;

  useEffect(() => {
    const severityLevel = calculateSeverityLevel(value, limit);

    // Update the cooldown period of severity level
    severityLevelCooldownRef.current[severityLevel] =
      Date.now() + severityLevelTimeouts[severityLevel];

    if (severityLevel !== 0) {
      // Clear the previous timeout if it exists
      if (holdTimeout.current) {
        clearTimeout(holdTimeout.current);
      }

      holdTimeout.current = setTimeout(
        update,
        severityLevelTimeouts[severityLevel]
      );
    }

    // Clean up the timeout on unmount or on severityLevel change
    return () => {
      if (holdTimeout.current) {
        clearTimeout(holdTimeout.current);
      }
    };
  }, [value]);

  const backgroundColor = backgroundColors[displayedSeverityLevel];

  return backgroundColor;
};

export const PanelItemParameter = ({
  title,
  value,
  soundLimit,
  unit,
}: PanelItemParameterProps) => {
  const [unitLabelPos, setUnitLabelPos] = useState<{ x: number; y: number }>();
  const [ref, { x, width, height }] = useMeasure<HTMLElement>();

  const hasValue = value !== null && value !== undefined;

  // Dynamically set position of unit label
  // useLayoutEffect(
  //   () => setUnitLabelPos({ x: x + width, y: height }),
  //   [x, width, height]
  // );

  const backgroundColor = useBackgroundColor(value, soundLimit);

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.1)",
          borderRadius: 1,
        }}
      >
        <Paper
          sx={{
            bgcolor: backgroundColor,
            color: "white",
            padding: {
              xs: 1,
              sm: 1.25,
              md: 1.5,
            },
            boxShadow: "inset -2px -1px 5px 0px rgba(0,0,0,0.25)",
            overflow: "hidden",
          }}
        >
          <Box position="relative">
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="absolute"
            >
              <Typography
                variant="h5"
                display="block"
                component="span"
                fontWeight="bold"
                sx={{
                  color: "rgba(255,255,255,0.9)",
                  fontSize: {
                    xs: "1.25rem",
                    sm: "1.35rem",
                    md: "1.5rem",
                  },
                }}
                noWrap
              >
                {title}
              </Typography>
            </Box>

            {soundLimit && (
              <Box position="absolute" right={0}>
                <Typography
                  sx={{
                    color: "rgba(255,255,255,0.9)",
                    fontSize: {
                      xs: "0.8rem",
                      sm: "0.8rem",
                      md: "1.1rem",
                    },
                    userSelect: "none",
                    lineHeight: 1,
                    backgroundColor: "rgba(0,0,0,0.25)",
                    padding: {
                      xs: 0.5,
                      md: 0.75,
                    },
                    borderRadius: 1,
                  }}
                  noWrap
                >
                  {formatSoundLimitToString(soundLimit)}
                </Typography>
              </Box>
            )}

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                position="relative"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  ref={ref}
                  display="block"
                  component="span"
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      xs: "3rem",
                      sm: "3.5rem",
                      md: "4.5rem",
                    },
                    lineHeight: 1.2,
                  }}
                >
                  {formatValueToString(value)}
                </Typography>

                {/* {unitLabelPos && (
                  <Typography
                    variant="h6"
                    sx={{
                      display: !hasValue ? "none" : null,
                      fontWeight: "bold",
                      color: "rgba(255,255,255,0.3)",
                      userSelect: "none",
                      position: "absolute",
                      left: unitLabelPos.x,
                      top: unitLabelPos.y - 36,
                    }}
                  >
                    {formatValueUnitTypeToString(unit)}
                  </Typography>
                )} */}
              </Box>
              {/* <Typography variant="h6" textAlign="center" fontWeight="400">
            {subtitle}
          </Typography> */}
            </Box>

            {/* <Box
        sx={{
          display: "flex",
          bgcolor: "rgba(175,175,175,0.5)",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "rgba(0,0,0,0.75)",
          borderRadius: 1,
          height: "26px",
          overflow: "hidden",
        }}
      >
        <Box width="5%" height="100%" bgcolor={colors.green} />
        <Box width="5%" height="100%" bgcolor={colors.green} />
        <Box width="0.5%" height="100%" bgcolor={colors.red} />
        <Box width="2%" height="100%" bgcolor={colors.orange} />
        <Box width="5%" height="100%" bgcolor={colors.green} />
        <Box width="5%" height="100%" bgcolor={colors.red} />
        <Box width="1%" height="100%" bgcolor={colors.green} />
        <Box width="3%" height="100%" bgcolor={colors.red} />
        <Box width="0.5%" height="100%" bgcolor={colors.red} />
        <Box width="1%" height="100%" bgcolor={colors.green} />
        <Box width="1%" height="100%" bgcolor={colors.red} />
        <Box width="5%" height="100%" bgcolor={colors.green} />
        <Box width="5%" height="100%" bgcolor={colors.green} />
        <Box width="5%" height="100%" bgcolor={colors.green} />
        <Box width="2%" height="100%" bgcolor={colors.orange} />
        <Box width="0.5%" height="100%" bgcolor={colors.red} />
        <Box width="22%" height="100%" bgcolor={colors.green} />
      </Box> */}

            {/* <Box border="1px solid black" bgcolor="white"></Box> */}
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
};
