import { Card, Grid } from "@mui/material";
import { FC, ReactNode } from "react";
import { ProjectDeviceRecordingStatusType } from "~/gql/graphql";
import { MemoizedProjectPanelHeader, ProjectPanelHeader } from "./ProjectPanelHeader";

export type ProjectPanelProps = {
  title: string;
  summary?: string | null;
  online?: boolean;
  recording?: ProjectDeviceRecordingStatusType | null;
  microphone?: string | null;
  metrics?: any;
  isLive?: boolean;
  children?: ReactNode;
  fullWidth?: boolean;
};

export const ProjectPanel: FC<ProjectPanelProps> = ({
  title,
  summary,
  online,
  children,
  recording,
  microphone,
  metrics,
  isLive,
  fullWidth,
}) => (
  <Grid item xs={12} sm={fullWidth ? 12 : 6}>
    <Card
      sx={{
        backgroundColor: (theme) => theme.palette.grey[800],
        color: (theme) => theme.palette.grey[200],
        px: { xs: 0.75, md: 1 },
        pb: 1,
      }}
    >
      <MemoizedProjectPanelHeader
        title={title}
        device={summary}
        microphone={microphone}
        recording={recording}
        batteryVoltage={metrics?.batteryVoltage}
        systemVoltage={metrics?.systemVoltage}
        signal={metrics?.signal}
        temperature={metrics?.temperature}
        status={!isLive ? "unknown" : online ? "online" : "offline"}
      />

      <Grid
        container
        spacing={{ xs: 0.6, md: 0.75 }}
        mt={{ xs: 0.25, md: 0.5 }}
      >
        {children}
      </Grid>
    </Card>
  </Grid>
);
