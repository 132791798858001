import BatteryStdIcon from "@mui/icons-material/BatteryStd";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import { Box, Typography } from "@mui/material";
import { FC, memo } from "react";
import { batteryVoltageFormat, temperatureFormat } from "~/formats";
import { ProjectDeviceRecordingStatusType } from "~/gql/graphql";
import { DeviceRecordingStatusIcon } from "./DeviceRecordingStatusIcon";
import { DeviceSignalIcon } from "./DeviceSignalIcon";

const statusIconColors = {
  online: "lightGreen",
  offline: "#FF7F7F",
  unknown: "#9E9E9E",
};

export type ProjectPanelHeaderProps = {
  title: string;
  device?: string | null;
  microphone?: string | null;
  batteryVoltage?: number | null;
  systemVoltage?: number | null;
  signal?: number | null;
  temperature?: number | null;
  recording?: ProjectDeviceRecordingStatusType | null;
  status?: "online" | "offline" | "unknown";
};

export const ProjectPanelHeader: FC<ProjectPanelHeaderProps> = ({
  title,
  device,
  microphone,
  batteryVoltage,
  systemVoltage,
  signal,
  temperature,
  recording,
  status,
}) => (
  <Box
    display="flex"
    flexWrap="nowrap"
    pt={{ xs: 1, md: 1.25 }}
    pl={{ xs: 0.25, md: 0.5 }}
    gap={2}
  >
    <Box flexGrow="1">
      <Typography fontSize={24} lineHeight={1.2} fontWeight="500" noWrap>
        {title}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        gap={0.75}
        flexWrap="wrap"
        mt={0.25}
      >
        <Box display="flex" alignItems="center">
          <Box
            borderRadius="50%"
            bgcolor={statusIconColors[status || "unknown"]}
            width="6px"
            height="6px"
            marginRight="4px"
            style={{ transition: "background .4s" }}
          />
          <Typography fontSize={12} lineHeight={1} color="grey.500">
            {device}
          </Typography>
        </Box>
        {microphone && (
          <Typography fontSize={12} lineHeight={1} color="grey.500">
            {microphone}
          </Typography>
        )}
        {temperature && (
          <Box
            display="flex"
            alignItems="center"
            fontSize={12}
            lineHeight={1}
            color={temperature < 10 || temperature > 40 ? "red" : "grey.500"}
          >
            <DeviceThermostatIcon
              sx={{
                color: "white",
                fontSize: 13,
                marginRight: 0.25,
                display: "none",
              }}
            />
            {temperatureFormat.format(temperature)} &#8451;
          </Box>
        )}
        {systemVoltage && (
          <Box
            display="flex"
            alignItems="center"
            fontSize={12}
            lineHeight={1}
            color={
              systemVoltage < 4.9 || systemVoltage > 5.1 ? "red" : "grey.500"
            }
          >
            <BatteryStdIcon
              sx={{
                color: "white",
                fontSize: 13,
                marginRight: 0.25,
                display: "none",
              }}
            />
            {batteryVoltageFormat.format(systemVoltage)} V
          </Box>
        )}
        {batteryVoltage && (
          <Box
            display="flex"
            alignItems="center"
            fontSize={12}
            lineHeight={1}
            color={
              batteryVoltage < 8 || batteryVoltage > 14.5 ? "red" : "grey.500"
            }
          >
            <BatteryStdIcon
              style={{ display: "initial" }}
              sx={{
                color: "white",
                fontSize: 13,
                marginRight: 0.25,
                display: "none !important",
              }}
            />
            {batteryVoltageFormat.format(batteryVoltage)} V
          </Box>
        )}
      </Box>
    </Box>
    <Box display="flex" alignSelf="center">
      <DeviceSignalIcon signal={signal} />
      <DeviceRecordingStatusIcon status={recording} />
    </Box>
  </Box>
);

export const MemoizedProjectPanelHeader = memo(ProjectPanelHeader);
