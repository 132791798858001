const octaveBand1_3FreqCorrections = [
  // {
  //   hz: 6.3,
  //   a: -85.4,
  //   c: -21.3,
  //   z: 0,
  // },
  // {
  //   hz: 8,
  //   a: -77.8,
  //   c: -17.7,
  //   z: 0,
  // },
  // {
  //   hz: 10,
  //   a: -70.4,
  //   c: -14.3,
  //   z: 0,
  // },
  // {
  //   hz: 12.5,
  //   a: -63.4,
  //   c: -11.2,
  //   z: 0,
  // },
  // {
  //   hz: 16,
  //   a: -56.7,
  //   c: -8.5,
  //   z: 0,
  // },
  {
    hz: 20,
    a: -50.5,
    c: -6.2,
    z: 0,
  },
  {
    hz: 25,
    a: -44.7,
    c: -4.4,
    z: 0,
  },
  {
    hz: 31.5,
    a: -39.4,
    c: -3,
    z: 0,
  },
  {
    hz: 40,
    a: -34.6,
    c: -2,
    z: 0,
  },
  {
    hz: 50,
    a: -30.2,
    c: -1.3,
    z: 0,
  },
  {
    hz: 63,
    a: -26.2,
    c: -0.8,
    z: 0,
  },
  {
    hz: 80,
    a: -22.5,
    c: -0.5,
    z: 0,
  },
  {
    hz: 100,
    a: -19.1,
    c: -0.3,
    z: 0,
  },
  {
    hz: 125,
    a: -16.1,
    c: -0.2,
    z: 0,
  },
  {
    hz: 160,
    a: -13.4,
    c: -0.1,
    z: 0,
  },
  {
    hz: 200,
    a: -10.9,
    c: 0,
    z: 0,
  },
  {
    hz: 250,
    a: -8.6,
    c: 0,
    z: 0,
  },
  {
    hz: 315,
    a: -6.6,
    c: 0,
    z: 0,
  },
  {
    hz: 400,
    a: -4.8,
    c: 0,
    z: 0,
  },
  {
    hz: 500,
    a: -3.2,
    c: 0,
    z: 0,
  },
  {
    hz: 630,
    a: -1.9,
    c: 0,
    z: 0,
  },
  {
    hz: 800,
    a: -0.8,
    c: 0,
    z: 0,
  },
  {
    hz: 1000,
    a: 0,
    c: 0,
    z: 0,
  },
  {
    hz: 1250,
    a: 0.6,
    c: 0,
    z: 0,
  },
  {
    hz: 1600,
    a: 1,
    c: -0.1,
    z: 0,
  },
  {
    hz: 2000,
    a: 1.2,
    c: -0.2,
    z: 0,
  },
  {
    hz: 2500,
    a: 1.3,
    c: -0.3,
    z: 0,
  },
  {
    hz: 3150,
    a: 1.2,
    c: -0.5,
    z: 0,
  },
  {
    hz: 4000,
    a: 1,
    c: -0.8,
    z: 0,
  },
  {
    hz: 5000,
    a: 0.5,
    c: -1.3,
    z: 0,
  },
  {
    hz: 6300,
    a: -0.1,
    c: -2,
    z: 0,
  },
  {
    hz: 8000,
    a: -1.1,
    c: -3,
    z: 0,
  },
  {
    hz: 10000,
    a: -2.5,
    c: -4.4,
    z: 0,
  },
  {
    hz: 12500,
    a: -4.3,
    c: -6.2,
    z: 0,
  },
  {
    hz: 16000,
    a: -6.6,
    c: -8.5,
    z: 0,
  },
  {
    hz: 20000,
    a: -9.3,
    c: -11.2,
    z: 0,
  },
];

const octaveBand1_1FreqCorrections = [
  // {
  //   hz: 8,
  //   a: -77.8,
  //   c: -17.7,
  //   z: 0,
  // },
  // {
  //   hz: 16,
  //   a: -56.7,
  //   c: -8.5,
  //   z: 0,
  // },
  {
    hz: 31.5,
    a: -39.4,
    c: -3,
    z: 0,
  },
  {
    hz: 63,
    a: -26.2,
    c: -0.8,
    z: 0,
  },
  {
    hz: 125,
    a: -16.1,
    c: -0.2,
    z: 0,
  },
  {
    hz: 250,
    a: -8.6,
    c: 0,
    z: 0,
  },
  {
    hz: 500,
    a: -3.2,
    c: 0,
    z: 0,
  },
  {
    hz: 1000,
    a: 0,
    c: 0,
    z: 0,
  },
  {
    hz: 2000,
    a: 1.2,
    c: -0.2,
    z: 0,
  },
  {
    hz: 4000,
    a: 1.0,
    c: -0.8,
    z: 0,
  },
  {
    hz: 8000,
    a: -1.1,
    c: -3.0,
    z: 0,
  },
  {
    hz: 16000,
    a: -6.6,
    c: -8.5,
    z: 0,
  },
];

export const octaveBandFreqCorrections = {
  "1/1": octaveBand1_1FreqCorrections,
  "1/3": octaveBand1_3FreqCorrections,
};
