import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  Fade,
} from "@mui/material";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from "recharts";
import { theme } from "~/theme";
import { useLocalStorage } from "react-use";
import { ProjectDeviceValueUnitType, ProjectLimitType } from "~/gql/graphql";
import { octaveBandFreqCorrections } from "./octaveBandFreqCorrections";
import { round } from "lodash";

import "./chart.css";

const units = {
  DB_A: "a",
  DB_C: "c",
  DB_Z: "z",
};

export type OctaveBandType = "1/1" | "1/3";

export type PanelItemSpectrumChart = {
  id: string;
  data?: {
    values: number[];
    holdValues?: number[];
  } | null;
  limits?: {
    type: ProjectLimitType;
    unitType: ProjectDeviceValueUnitType;
    value: number;
  }[];
};

const backgroundColors = {
  default: "#78909C",
  orange: "#FF6F00",
  red: "#C62828",
};

const calculate1_1OctaveGraphData = (data?: {
  values: number[];
  holdValues?: number[];
}): SpectrumGraphData | null => {
  if (data && data.values && data.values.length >= 36) {
    const count = Math.floor(data.values.length / 3);

    const live = [...Array(count)].map((_, i) => {
      const input1 = data.values[i * 3];
      const input2 = data.values[i * 3 + 1];
      const input3 = data.values[i * 3 + 2];

      return round(
        10 *
          Math.log10(
            Math.pow(10, input1 / 10) +
              Math.pow(10, input2 / 10) +
              Math.pow(10, input3 / 10)
          ),
        1
      );
    });

    const hold: number[] =
      data?.holdValues && data.holdValues.length >= 36
        ? [...Array(count)]
            .map((_, i) => {
              if (data.values.length < i * 3 + 2) {
                return null;
              }

              const input1 = data.holdValues![i * 3];
              const input2 = data.holdValues![i * 3 + 1];
              const input3 = data.holdValues![i * 3 + 2];

              return round(
                10 *
                  Math.log10(
                    Math.pow(10, input1 / 10) +
                      Math.pow(10, input2 / 10) +
                      Math.pow(10, input3 / 10)
                  ),
                1
              );
            })
            .filter((x) => x !== null)
        : null;

    return {
      resolution: "1/1",
      values: {
        live: live.slice(2),
        hold: hold?.slice(2),
      },
    };
  }

  return null;
};

const calculateGraphData = (data?: {
  values: number[];
  holdValues?: number[];
}): SpectrumGraphData[] => {
  if (!data) {
    return [];
  }

  const inputResolution: OctaveBandType =
    data.values.length <= 12 ? "1/1" : "1/3";
  const inputResolutionData = {
    resolution: inputResolution,
    values: {
      live: data.values.slice(inputResolution === "1/3" ? 5 : 2),
      hold: data.holdValues?.slice(inputResolution === "1/3" ? 5 : 2),
    },
  };

  if (inputResolution === "1/3") {
    // 1/3 octave allows for calculation of 1/1 octave based on received values
    const octave1_1Data = calculate1_1OctaveGraphData(data);

    if (octave1_1Data) {
      return [octave1_1Data, inputResolutionData];
    }
  }

  return [inputResolutionData];
};

type SpectrumGraphData = {
  resolution: OctaveBandType;
  values: {
    live: number[];
    hold?: number[];
  };
};

export const calculateBackgroundColor = (level: number, limit: number) => {
  if (!limit || !level) return backgroundColors["default"];
  if (level >= limit) return backgroundColors["red"];
  if (level >= limit - 3) return backgroundColors["orange"];

  return backgroundColors["green"];
};

export const PanelItemSpectrumChart: FC<PanelItemSpectrumChart> = ({
  id,
  data,
  limits,
}) => {
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [selectedUnit, setSelectedUnit] =
    useLocalStorage<ProjectDeviceValueUnitType>(
      `spectrum-unit-type-${id}`,
      "DB_Z"
    );

  const graphData = calculateGraphData(data);

  const [selectedResolution, setSelectedResolution] =
    useLocalStorage<OctaveBandType>(`spectrum-resolution-${id}`, "1/3");

  useEffect(() => {
    if (!graphData.some((x) => x.resolution === selectedResolution)) {
      setSelectedResolution(graphData[0]?.resolution || "1/3");
    }
  }, [graphData]);

  const activeGraphData = graphData.find(
    (x) => x.resolution === selectedResolution
  );

  const hasData = graphData.length > 0;

  const weightedData = useMemo(
    () =>
      (activeGraphData?.values?.live || [])
        .map((value, i) => {
          const freqCorrections =
            octaveBandFreqCorrections[selectedResolution!][i];

          if (!freqCorrections) {
            return null;
          }

          const hold = activeGraphData?.values?.hold?.[i] || 0;
          const correctedValue =
            selectedUnit !== "DB_Z"
              ? roundUp(value + freqCorrections[units[selectedUnit!]])
              : value;
          const correctedHoldValue =
            hold && selectedUnit !== "DB_Z"
              ? roundUp(hold + freqCorrections[units[selectedUnit!]])
              : hold;

          let holdDifference =
            Math.abs(correctedHoldValue) - Math.abs(correctedValue);
          // let holdDifference = Math.abs(hold - value);
          holdDifference = holdDifference < 1 ? 0 : holdDifference;
          // const holdd = Math.abs(hold) - 1 <= Math.abs(value) ? value - 1 : hold;

          return {
            hertz: freqCorrections.hz,
            value: correctedValue,
            hold: correctedHoldValue,
            holdDifference: holdDifference,
            unit: selectedUnit,
            isSelected: false,
            // isSelected: selectedBars?.includes(frequency),
          };
        })
        .filter((val) => !!val),
    [activeGraphData, selectedUnit]
  );

  const values = [...weightedData.map((x) => x.value)];
  const allValues = [...values, ...weightedData.map((x) => x.hold)];
  const max = !allValues.length ? 0 : Math.max(...allValues);

  const ticksY = [50, 70, 90, 110, 130];

  const visibleLimits =
    limits?.filter((limit) => limit.unitType === selectedUnit) || [];

  const highestValueIndex = values.findIndex(
    (x) => x === (!values.length ? -1 : Math.max(...values))
  );

  const graphKey = `${selectedResolution}`;

  return (
    <Grid
      item
      xs={12}
      style={{
        userSelect: "none",
        overflowX: "auto",
      }}
    >
      <ResponsiveContainer
        width="100%"
        height={isMdUp ? 250 : 200}
        minWidth={250}
      >
        <ComposedChart
          key={graphKey}
          width={690}
          height={250}
          data={weightedData}
          margin={{
            top: isMdUp ? 12 : 8,
            right: isMdUp ? 12 : 12,
            left: isMdUp ? 18 : 16,
            bottom: 2,
          }}
        >
          <CartesianGrid vertical={false} opacity={0.75} />
          <XAxis
            height={25}
            interval={0}
            dataKey="hertz"
            padding={{ left: 0, right: 0 }}
            stroke="#9E9E9E"
            ticks={
              selectedResolution === "1/3"
                ? [0, 31.5, 63, 125, 250, 500, 1000, 2000, 4000, 8000, 20000]
                : [
                    8, 16, 31.5, 63, 125, 250, 500, 1000, 2000, 4000, 8000,
                    16000,
                  ]
            }
            tickFormatter={formatHertzTickValue}
            tick={{ fill: "#E0E0E0", fontSize: 12 }}
          />
          <YAxis
            width={25}
            padding={{ top: 10 }}
            stroke="#9E9E9E"
            allowDataOverflow
            allowDecimals={false}
            domain={[ticksY[0], "auto"]}
            ticks={ticksY}
            tick={{ fill: hasData ? "#E0E0E0" : "none", fontSize: 12 }}
          />
          <Bar stackId="1" dataKey="value" fill="#78909C" cursor="pointer">
            {weightedData.map((entry, i) => {
              const isSelected = false;
              // const isSelected = selectedBars?.includes(entry.hertz);
              const isLimitWarning = visibleLimits.some(
                (limit) => entry.value >= limit.value - 3
              );
              const isLimit = visibleLimits.some(
                (limit) => entry.value >= limit.value
              );

              return (
                <Cell
                  key={i}
                  fill={
                    isSelected
                      ? "#2979FF"
                      : isLimit
                      ? "#D50000"
                      : isLimitWarning
                      ? backgroundColors["orange"]
                      : "#78909C"
                  }
                />
              );
            })}
          </Bar>
          <Bar
            stackId="1"
            dataKey="holdDifference"
            fill="#BDBDBD"
            shape={<HoldBarShape />}
          >
            {weightedData.map((entry, i) => {
              // const isSelected = selectedBars?.includes(entry.hertz);
              const isSelected = false;
              const isLimitWarning = visibleLimits.some(
                (limit) => entry.hold >= limit.value - 3
              );
              const isLimit = visibleLimits.some(
                (limit) => entry.hold >= limit.value
              );

              return (
                <Cell
                  key={i}
                  fill={
                    isSelected
                      ? "#2979FF"
                      : isLimit
                      ? "#D50000"
                      : isLimitWarning
                      ? backgroundColors["orange"]
                      : "#BDBDBD"
                  }
                />
              );
            })}
          </Bar>

          {visibleLimits.map((limit, i) => (
            <ReferenceLine
              key={i}
              y={limit.value}
              stroke="#D50000"
              strokeWidth={1.5}
            />
          ))}
          <RechartsTooltip
            content={<ChartTooltip />}
            position={{ y: 10 }}
            // cursor={{ fill: "rgba(255,255,255,0.1)" }}
          />
        </ComposedChart>
      </ResponsiveContainer>

      <Fade in={true}>
        <Box
          sx={{
            paddingTop: 0,
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1,
            color: "grey.300",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            size="small"
            value={selectedUnit}
            exclusive
            // disabled={!hasData}
            onChange={(e, value) => {
              value && setSelectedUnit(value);
            }}
          >
            <ToggleButton
              style={{ textTransform: "none", paddingTop: 4, paddingBottom: 4 }}
              value="DB_A"
            >
              dB(A)
            </ToggleButton>
            <ToggleButton
              style={{ textTransform: "none", paddingTop: 4, paddingBottom: 4 }}
              value="DB_C"
            >
              dB(C)
            </ToggleButton>
            <ToggleButton
              style={{ textTransform: "none", paddingTop: 4, paddingBottom: 4 }}
              value="DB_Z"
            >
              dB(Z)
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            size="small"
            value={selectedResolution}
            exclusive
            disabled={!data}
            onChange={(e, value) => {
              value && setSelectedResolution(value);
            }}
          >
            {(!data ? [{ resolution: "1/1" }] : graphData).map(
              ({ resolution }) => (
                <ToggleButton
                  key={resolution}
                  style={{
                    textTransform: "none",
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                  value={resolution}
                >
                  {resolution}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        </Box>
      </Fade>
    </Grid>
  );
};

const CustomizedLabel = (props) => {
  const { x, y, fill, value } = props;
  return (
    <text
      x={x}
      y={y}
      dy={-4}
      fontSize="16"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="middle"
    >
      {value}%
    </text>
  );
};

const HoldBarShape = (props) => {
  const { fill, x, y, width, height } = props;

  return (
    <g>
      {/* <rect
        x={x}
        y={y - 0.75}
        width={width}
        height={show ? 1.5 : 0}
        // opacity={show ? 1 : 0}
        // height={height > 0 ? 1.5 : 0}
        stroke="none"
        fill="red"
      /> */}
      <rect
        x={x}
        y={y - 0.75}
        width={width}
        height={height > 0 ? 1.5 : 0}
        stroke="none"
        fill={fill}
      />
    </g>
  );
};

const numberFormat = new Intl.NumberFormat("nl-NL", {});

const numberFormatSoundLevel = new Intl.NumberFormat("nl-NL", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const tooltipUnitTypeLabels = {
  DB_A: "dBA",
  DB_C: "dBC",
  DB_Z: "dBZ",
};

const ChartTooltip: FC<any> = ({ active, payload, label, ...props }) => {
  if (active && payload && payload.length) {
    const { hertz, value, hold, unit, isSelected } = payload[0].payload;

    return (
      <Box
        sx={{
          bgcolor: "#616161",
          opacity: 0.8,
          color: "white",
          paddingX: 0.75,
          paddingY: 0.25,
          borderRadius: 1,
          boxShadow: 2,
          userSelect: "none",
        }}
      >
        <Box
          component="span"
          style={{
            fontWeight: isSelected ? "extrabold" : "bold",
            color: isSelected ? "#90CAF9" : undefined,
          }}
        >
          {numberFormat.format(hertz)} Hz
        </Box>
        <br />
        <Box sx={{ display: "flex", marginTop: 0.25 }}>
          <div>
            LIVE
            <br />
            HOLD
          </div>
          <Box sx={{ paddingLeft: 2, marginRight: 0.5, fontWeight: "bold" }}>
            {numberFormatSoundLevel.format(value)}
            <br />
            {numberFormatSoundLevel.format(hold)}
          </Box>
          <Box sx={{ fontWeight: "bold" }}>
            {tooltipUnitTypeLabels[unit]}
            <br />
            {tooltipUnitTypeLabels[unit]}
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

const generateTicksY = (maxValue: number): number[] => {
  const defaultTicks = [40, 60, 80, 100, 120];

  const ticksMax = [...Array(Math.ceil(maxValue / 20))]
    .map((_, i) => (i + 1) * 20)
    .slice(-5);

  const array = [...defaultTicks, ...ticksMax]
    .reduce((acc, x) => {
      if (!acc.includes(x)) {
        return [...acc, x];
      }
      return [...acc];
    }, [] as number[])
    .sort((a, b) => a - b);

  return array;
};

const roundUp = (num, precision = 1) => {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};

const formatHertzTickValue = (num) => {
  num = num.toString().replace(/[^0-9.]/g, "");
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: "k" },
    { v: 1e6, s: "m" },
    { v: 1e9, s: "b" },
    { v: 1e12, s: "t" },
    { v: 1e15, s: "p" },
    { v: 1e18, s: "e" },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (
    (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
    si[index].s
  );
};
