import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNetworkStatus } from "../hooks/useNetworkStatus";

export const ConnectionWarningDialog = () => {
  const { isOnline } = useNetworkStatus();

  useEffect(() => {
    setOpen(!isOnline);
  }, [isOnline]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Geen verbinding</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Het lijkt erop dat uw apparaat geen verbinding heeft met internet. U
          heeft een actieve internetverbinding nodig om live-weergave te kunnen
          weergeven. Klik op OK om dit bericht te sluiten.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="white" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
