if (typeof window !== "undefined" && !!import.meta.env.PROD) {
  setInterval(() => {
    fetch("/build.txt", {
      method: "GET",
      cache: "no-store",
    })
      .then((response) => response.text())
      .then((response) => {
        if (response.length === 40 && response !== __APP_GIT_COMMIT_SHA__) {
          console.log("Detected different build version, reloading window..");
          window.location.reload();
        }
      })
      .catch((ignored) => {});
  }, 60000);
}
