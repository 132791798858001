import { Box, Grid, Paper } from "@mui/material";
import { ProjectDeviceValueUnitType, ProjectLimitType } from "~/gql/graphql";
import { PanelItemSpectrumChart } from "./PanelItemSpectrumChart";

export type PanelItemSpectrumProps = {
  device: any;
  data?: {
    values?: number[];
    holdValues?: number[];
  } | null;
  limits?: {
    type: ProjectLimitType;
    unitType: ProjectDeviceValueUnitType;
    value: number;
  }[];
};

export const PanelItemSpectrum = ({
  device,
  data,
  limits,
}: PanelItemSpectrumProps) => (
  <Grid item xs={12}>
    <Box
      sx={{
        border: "1px solid rgba(0,0,0,0.1)",
        borderRadius: 1,
      }}
    >
      <Paper
        sx={{
          position: "relative",
          bgcolor: "#2e2e2e",
          padding: 0,
          overflow: "hidden",
        }}
      >
        <PanelItemSpectrumChart id={device.id} data={data} limits={limits} />
      </Paper>
    </Box>
  </Grid>
);
