import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

export const apolloClient = new ApolloClient({
  uri: import.meta.env.viteVITE_GRAPHQL_API_URL,
  cache: new InMemoryCache(),
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    new GraphQLWsLink(
      createClient({
        url: import.meta.env.VITE_GRAPHQL_API_URL_WS,
        shouldRetry: () => true,
      })
    ),
    new HttpLink({
      uri: import.meta.env.VITE_GRAPHQL_API_URL,
    })
  ),
});
