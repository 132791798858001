import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { ProjectQuery } from "~/gql/graphql";
import { useProjectLiveSubscription } from "../hooks/useProjectLiveSubscription";
import { useSpectrumSubscription } from "../hooks/useSpectrumSubscription";
import { ProjectPanel } from "./panel/ProjectPanel";
import { PanelItemParameter } from "./parameter/PanelItemParameter";
import { ProjectHeader } from "./ProjectHeader";
import { PanelItemSpectrum } from "./spectrum/PanelItemSpectrum";
import { ConnectionWarningDialog } from "./ConnectionWarningDialog";

export type ProjectProps = {
  data: NonNullable<ProjectQuery["project"]>;
};

type SpectrumDataState = {
  [id: string]: {
    values: number[];
    holdValues: number[];
  };
};

export const Project = ({ data }: ProjectProps) => {
  const { data: liveData } = useProjectLiveSubscription(data.id);
  const [spectrumData, setSpectrumData] = useState<SpectrumDataState>({});

  useSpectrumSubscription(data.id, ({ data }) => {
    const spectrum = data.data?.spectrum;

    if (spectrum) {
      setSpectrumData((spectrumData) => ({
        ...spectrumData,
        [spectrum.id]: {
          values: spectrum.values,
          holdValues: spectrum.holdValues,
        },
      }));
    }
  });

  return (
    <Grid
      container
      spacing={{ xs: 0.75, sm: 0.75, md: 1.5 }}
      justifyContent="center"
    >
      <ProjectHeader
        title={data.title}
        date={dayjs().toDate()}
        startDate={data.startDate}
        endDate={data.endDate}
      />
      <ConnectionWarningDialog />

      {[...data.devices]
        .sort((a, b) => (a?.sort || 0) - (b?.sort || 0))
        .map((device) => {
          const liveDevice = liveData?.devices?.find((x) => x.id === device.id);
          const limits = data.limits?.filter((x) =>
            x.projectDevices.includes(device.id)
          );
          const activeLimits =
            limits?.filter(
              (x) =>
                (!x.activeFrom ||
                  new Date(x.activeFrom).getTime() < Date.now()) &&
                (!x.activeUntil ||
                  new Date(x.activeUntil).getTime() > Date.now())
            ) || [];

          return (
            <ProjectPanel
              key={device.id}
              title={device.title}
              summary={device.summary}
              isLive={!!liveData}
              online={liveDevice?.online || false}
              recording={liveDevice?.recording}
              microphone={liveDevice?.microphone}
              metrics={liveDevice?.metrics}
            >
              {[...device.values]
                .sort((a, b) => (a?.sort || 0) - (b?.sort || 0))
                .map((value) => (
                  <PanelItemParameter
                    key={value.id}
                    title={value.title}
                    unit={value.unit}
                    soundLimit={
                      activeLimits?.find((x) => x.unitType === value.unit)
                        ?.value
                    }
                    value={
                      liveData?.devices
                        ?.find((x) => x.id === device.id)
                        ?.values?.find((x) => x.id === value.id)?.value || null
                    }
                  />
                ))}

              <PanelItemSpectrum
                device={device}
                data={liveDevice?.online ? spectrumData[device.id] : null}
                limits={activeLimits}
              />
            </ProjectPanel>
          );
        })}
    </Grid>
  );
};
