import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { IconButton } from "@mui/material";
import { FC } from "react";

export const FullscreenToggle: FC = () => {
  const hasFullscreenCapabilities = !!document.body.requestFullscreen;

  return (
    hasFullscreenCapabilities && (
      <IconButton
        color="inherit"
        size="small"
        onClick={() => {
          if (!document.fullscreenElement) {
            document.body.requestFullscreen({
              navigationUI: "hide",
            });
            // screen.orientation.lock("landscape-primary");
          } else {
            document.exitFullscreen();
          }
        }}
      >
        {!document.fullscreenElement ? (
          <FullscreenIcon
            sx={{
              fontSize: { sm: "24px", md: "35px" },
            }}
          />
        ) : (
          <FullscreenExitIcon
            sx={{
              fontSize: { sm: "24px", md: "35px" },
            }}
          />
        )}
      </IconButton>
    )
  );
};
