import { useSubscription } from "@apollo/client";
import { graphql } from "~/gql";

const projectLiveSubscription = graphql(`
  subscription ProjectLive($id: ID!) {
    projectLive(id: $id) {
      devices {
        id
        online
        recording
        microphone
        values {
          id
          value
        }
        metrics {
          batteryVoltage
          systemVoltage
          signal
          temperature
        }
      }
    }
  }
`);

export const useProjectLiveSubscription = (projectId: string) => {
  const { data, loading, error } = useSubscription(projectLiveSubscription, {
    fetchPolicy: "network-only",
    // shouldResubscribe: true,
    variables: {
      id: projectId,
    },
  });

  return { data: data?.projectLive };
};
