import { Box, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ReactElement, useState } from "react";
import { useInterval } from "react-use";
import { FullscreenToggle } from "./FullscreenToggle";

const Clock = (): ReactElement => {
  const [date, setDate] = useState(new Date());
  useInterval(() => setDate(new Date()), 500);

  // @ts-ignore
  return date ? dayjs(date).format("HH:mm") : null;
};

export type ProjectHeaderProps = {
  title: string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
};

export const ProjectHeader = ({
  title,
  date,
  startDate,
  endDate,
}: ProjectHeaderProps) => (
  <Grid item xs={12}>
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.grey[800],
        color: (theme) => theme.palette.grey[200],
        paddingX: 1.5,
        paddingY: { xs: 1, sm: 1, md: 1.5 },
      }}
    >
      <Box position="relative">
        <Box borderRadius="50%" sx={{ backgroundColor: "green" }}></Box>
        <Typography
          noWrap
          sx={{
            display: { xs: "none", sm: "block" },
            fontWeight: "bold",
            fontSize: { xs: 24, sm: 24, md: 34 },
            lineHeight: 1.2,
            position: "absolute",
            left: "50%",
            top: "3px",
            transform: "translateX(-50%)",
          }}
        >
          {date && dayjs(date).format("DD-MM-YYYY")}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            noWrap
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 24, sm: 24, md: 34 },
              lineHeight: 1.2,
            }}
          >
            {title}
          </Typography>

          <Box display="flex" alignItems="center">
            {(startDate || endDate) && (
              <Box
                display={{ xs: "none", sm: "flex" }}
                flexDirection="row"
                mr={2}
              >
                <Typography
                  fontSize="14px"
                  color="grey.400"
                  lineHeight={1.2}
                  mr={2}
                >
                  {startDate && "Begintijd:"}
                  <br />
                  {endDate && "Eindtijd:"}
                </Typography>
                <Typography lineHeight={1.2} fontSize="14px" fontWeight="bold">
                  {startDate && dayjs(startDate).format("HH:mm")}
                  <br />
                  {endDate && dayjs(endDate).format("HH:mm")}
                </Typography>
              </Box>
            )}
            <Typography
              noWrap
              sx={{
                display: { xs: "none", sm: "block" },
                fontWeight: "bold",
                fontSize: { xs: 24, sm: 24, md: 34 },
                lineHeight: 1.2,
              }}
            >
              <Clock />
            </Typography>

            <FullscreenToggle />
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            width: "100%",
            mr: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            color="grey.300"
            mb={1}
          >
            <span>{date && dayjs(date).format("DD-MM-YYYY")}</span>
            <span>
              <Clock />
            </span>
          </Box>
          <Box display="flex" flexDirection="row">
            <Typography fontSize="14px" mr={2}>
              Begintijd:
              <br />
              Eindtijd:
            </Typography>
            <Typography fontSize="14px" fontWeight="bold">
              {dayjs(startDate).format("HH:mm")}
              <br />
              {dayjs(endDate).format("HH:mm")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  </Grid>
);
