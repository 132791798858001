import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Tooltip } from "@mui/material";
import { FC } from "react";
import { ProjectDeviceRecordingStatusType } from "~/gql/graphql";

const iconComponent = {
  FROZEN: {
    icon: PauseIcon,
    tooltip: "Meting bevroren",
  },
  PAUSED: {
    icon: PauseIcon,
    tooltip: "Meting gepauzeerd",
  },
  RUNNING: {
    icon: PlayArrowIcon,
    tooltip: "Meting loopt",
  },
  SETTLING: {
    icon: SyncIcon,
    tooltip: "Meting start..",
  },
  STOPPING: {
    icon: SyncIcon,
    tooltip: "Meting stopt..",
  },
  STOPPED: {
    icon: StopIcon,
    tooltip: "Meting gestopt",
  },
};

type DeviceRecordingStatusIconProps = {
  status?: ProjectDeviceRecordingStatusType | null;
};

export const DeviceRecordingStatusIcon: FC<DeviceRecordingStatusIconProps> = ({
  status,
}) => {
  if (!status) {
    return null;
  }

  const { icon: Icon, tooltip } = iconComponent[status] || { icon: null };

  if (!iconComponent[status]) {
    return null;
  }

  return (
    <Tooltip title={tooltip} placement="top" enterTouchDelay={0}>
      <Box display="flex" p={1}>
        {Icon ? <Icon /> : <Box width={24} height={24} />}
      </Box>
    </Tooltip>
  );
};
