import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "./pages/home/HomePage";
import { ProjectLivePage } from "./pages/project-live/ProjectLivePage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/project/:projectId",
    element: <ProjectLivePage />,
  },
]);
