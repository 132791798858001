import { Helmet } from "react-helmet";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { Project } from "./components/Project";
import { ProjectErrorDialog } from "./components/ProjectErrorDialog";
import { useProjectQuery } from "./hooks/useProjectQuery";

export const ProjectLivePage = () => {
  const { projectId } = useParams();
  const { status, data } = useProjectQuery(projectId!!);

  return (
    <>
      {status === "success" && (
        <Helmet>
          <title>{data?.project?.title} - Geluidsmonitor.nl</title>
        </Helmet>
      )}
      <Box
        id="main"
        component="main"
        width="100%"
        p={{ xs: 0.75, sm: 0.75, md: 2 }}
      >
        {status === "success" && <Project data={data?.project!!} />}
      </Box>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!(status === "success")}
        appear={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ProjectErrorDialog status={status} />
    </>
  );
};
