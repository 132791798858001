import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#004d40",
    },
    secondary: {
      main: "#5c6bc0",
      light: "#7c88cc",
      dark: "#404a86",
    },
  },
});
