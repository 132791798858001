import { useQuery } from "@apollo/client";
import { graphql } from "~/gql";

const projectQuery = graphql(`
  query Project($id: ID!) {
    project(id: $id) {
      id
      title
      date
      startDate
      endDate
      devices {
        id
        title
        sort
        summary
        online
        recording
        values {
          id
          title
          sort
          unit
        }
      }
      limits {
        activeFrom
        activeUntil
        type
        unitType
        value
        projectDevices
      }
    }
  }
`);

export type ProjectQueryStatus = "error" | "loading" | "success" | "not-found";

export const useProjectQuery = (projectId: string) => {
  const { data, loading, error } = useQuery(projectQuery, {
    variables: {
      id: projectId,
    },
  });

  return {
    status: (error
      ? "error"
      : loading
      ? "loading"
      : data?.project
      ? "success"
      : "not-found") as ProjectQueryStatus,
    loading,
    data,
    error,
  };
};
